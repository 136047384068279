<template>
  <div class="detail-page">
    <div class="detail-header">
      <img @click="back" src="@/assets/img/zhang/back.png" alt="" />
      <span>信息详情</span>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="box">
        <!-- 标题的那一些 -->
        <div class="boxhead">
          <img src="@/assets/img/gjh/head-l.png" class="box-img-l" />
          <img src="@/assets/img/gjh/head-r.png" class="box-img-r" />
          <div class="boxheadBox">
            <div class="boxheadBox-title" v-html="detailData.title"></div>
            <div class="boxheadBox-desc m-t-big">
              <div class="red" v-if="detailData.bodyTypeName">
                {{ detailData.bodyTypeName }}
              </div>
              <div class="yellow m-l-16" v-if="detailData.platformTypeName">
                {{ detailData.platformTypeName }}
              </div>
              <div class="m-l-16 time">发布时间：{{ detailData.publishTime }}</div>
            </div>
            <div class="boxheadBox-desc m-t-big">
              <div
                v-if="!detailData.isCollect"
                class="cursor"
                @click="setCollection('1', specialDetailId)"
              >
                <img width="14px" src="@/assets/img/gjh/collection.png" alt="" class="m-r-8" />
                收藏
              </div>
              <div
                v-if="detailData.isCollect"
                class="cursor"
                @click="setCollection('2', specialDetailId)"
              >
                <img src="@/assets/img/zhang/yishoucang.png" alt="" style="margin-right: 0.625rem" />
                <div style="color: #ff9000">已收藏</div>
              </div>
              <div class="m-l-40 cursor" @click="$main.copyFun(detailData.joiningTogether)">
                <img width="14px" src="@/assets/img/gjh/fz.png" alt="" class="m-r-8" />
                复制原文
              </div>
              <div class="m-l-40 ckyw">
                <img src="@/assets/img/gjh/ckyw-blue.png" alt="" class="m-r-8" />
                <a :href="detailData.sourceUrl" target="_blank">查看原文</a>
              </div>
            </div>
          </div>
        </div>
        <!-- 原文链接之类的 -->
        <div class="boxUilts m-t-big">
          <div class="boxUilts-l">
            <div>
              原文链接：
              <a
                :href="detailData.sourceUrl"
                style="
                  width: 50%;
                  overflow: hidden;
                  word-break: keep-all;
                  white-space: nowrap;
                  text-overflow: ellipsis"
                target="_blank"
              >
                {{ detailData.sourceUrl || "无" }}
              </a>
              <img src="@/assets/img/gjh/copy.png" @click="$main.copyFun(detailData.sourceUrl)" />
            </div>
            <div>
              发布时间：{{ detailData.publishTime || "无" }}
              <img src="@/assets/img/gjh/copy.png" @click="$main.copyFun(detailData.publishTime)" />
            </div>
            <div class="m-t-big">
              网站名称：{{ detailData.websiteName || "无" }}
              <img src="@/assets/img/gjh/copy.png" @click="$main.copyFun(detailData.websiteName)" />
            </div>
            <div class="m-t-big">
              所属平台：{{ detailData.platformTypeName || "无" }}
              <img
                src="@/assets/img/gjh/copy.png"
                @click="$main.copyFun(detailData.platformTypeName)"
              />
            </div>
            <div class="m-t-big">
              关键字：{{ detailData.matchedKeyword || "无" }}
              <img
                src="@/assets/img/gjh/copy.png"
                @click="$main.copyFun(detailData.matchedKeyword)"
              />
            </div>
          </div>
          <div class="boxUilts-r">
            <img src="@/assets/img/gjh/zhanwei.png" />
          </div>
        </div>
        <!-- 内容 -->
        <div class="boxContent m-t-big" v-html="detailData.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import Special from "@/api/special/index";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      routerList: ["系统设置", "信息详情"], //面包屑
      subjectType: [], //主体类型
      platformType: [], //平台类型
      specialDetailId: null, //详情id
      detailData: {}, //详情内容
      specialActionsId: null, //专项行动id
      title: "", //专项行动标题
    };
  },
  methods: {
    // 收藏/取消收藏
    async setCollection(type, data) {
      let url = "";
      if (type == "1") {
        url = "/module-polyse/planSpecialData/collectMsg/";
      }
      if (type == "2") {
        url = "module-polyse/planSpecialData/cancelCollection/";
      }
      const res = await Special.setCollection(url, data);
      if (res.data.code == 200) {
        this.$message.success("操作成功！");
        this.getDetailsInDetails();
      }
    },
    // 获取详情
    async getDetailsInDetails() {
      const res = await Special.getDetailsInDetails(this.specialDetailId);
      if (res.data.code == 200) {
        this.detailData = res.data.data;
        this.subjectType.forEach((x) => {
          if (x.value == this.detailData.bodyType) {
            this.detailData.bodyTypeName = x.label;
          }
        });
        this.platformType.forEach((y) => {
          if (y.value == this.detailData.platformType) {
            this.detailData.platformTypeName = y.label;
          }
        });
      }
    },
    back() {
      this.$router.push("/settings");
    },
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.specialDetailId = params.id;
      this.title = params.title;
      this.specialActionsId = params.specialActionsId;
      this.subjectType = params.subjectType;
      this.platformType = params.platformType;
    }
    this.getDetailsInDetails();
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
.detail-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  .detail-header {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    display: flex;
    justify-content: start;
    img {
      width: 38px;
      height: 38px;
      margin: 9px 15px 9px 23px;
      cursor: pointer;
    }
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 56px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
  .page {
    padding-top: 16px;
    .box {
      margin-top: 24px;
      padding: 40px;
      background: #ffffff;
      .boxhead {
        width: 100%;
        min-height: 210px;
        padding-left: 200px;
        padding-right: 200px;
        padding-top: 40px;
        padding-bottom: 32px;
        position: relative;
        border: 1px solid #e4e6ec;
        .boxheadBox {
          width: 100%;
          .boxheadBox-title {
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .boxheadBox-desc {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            div {
              display: flex;
              align-items: center;
            }
            .red {
              background: #ff4b1f;
              padding: 4px 8px;
              font-weight: 500;
              color: #ffffff;
            }
            .yellow {
              background: #f58030;
              padding: 4px 8px;
              font-weight: 500;
              color: #ffffff;
            }
            .time {
              color: #666666;
            }
            .ckyw {
              color: #2e59ec;
            }
          }
        }
        .box-img-l {
          height: 74px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .box-img-r {
          height: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      .boxUilts {
        margin-left: 200px;
        width: calc(100% - 400px);
        height: 156px;
        background: #f4f5fa;
        padding: 24px 24px 24px 32px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;
        justify-content: space-between;
        .boxUilts-l {
          width: calc(100% - 111px);
          display: flex;
          flex-wrap: wrap;
          div {
            width: 50%;
            display: flex;
            align-items: center;
            img {
              margin-left: 16px;
              cursor: pointer;
            }
          }
        }
        .boxUilts-r {
          width: 111px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .boxContent {
        margin-left: 200px;
        width: calc(100% - 400px);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        text-indent: 25px;
      }
    }
  }
}
</style>
